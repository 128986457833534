import React from 'react'
import { useMsal } from '@azure/msal-react'
import PersonIcon from '@mui/icons-material/Person'

const UserProfile = () => {
  const { instance, accounts } = useMsal()

  const handleLogin = (loginType: any) => {
    if (loginType === 'popup') {
      instance.loginPopup().catch((e) => {
        console.error(e)
      })
    } else {
      instance.loginRedirect().catch((e) => {
        console.error(e)
      })
    }
  }

  const handleLogout = () => {
    const answer = window.confirm('Are you sure you want to sign out?')
    if (!answer) return
    instance.logout().catch((e) => {
      console.error(e)
    })
  }

  return (
    <div className="blue-background">
      <div className="row space-between binned-width">
        {accounts.length > 0 ? (
          <>
            <div className="row">
              <PersonIcon />
              {accounts[0].name}
            </div>
            <button onClick={handleLogout}>Logg ut</button>
          </>
        ) : (
          <div className="column align-center flex1">
            <h1>Logg inn for å bruke appen</h1>
            <button className="big-button" onClick={() => handleLogin('popup')}>
              Logg in
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default UserProfile
