import felleskatalogen from './felleskatalogen'
import vestreviken from './vestreviken'
import hdir from './hdir'
import infobutton from './infobutton'
import demo from './demo'

const configs = {
  felleskatalogen,
  vestreviken,
  hdir,
  demo,
  infobutton
}

export default configs
