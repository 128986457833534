import React from 'react'
import '../index.css'

export const Header = class Header extends React.Component {
  render() {
    return (
      <header>
        <div className="binned-width">
          <img
            className="header-logo"
            src="assets/logoSemanticPulse.png"
            alt="Logo Semantic Pulse"
            width="100%"
          />
        </div>
      </header>
    )
  }
}

export default Header
